import { useCallback } from 'react';

import { IconsEnum } from '../../../../assets/icons/types';

import { usePaginationForm } from './hooks/usePaginationForm';

import { Form } from '../../../../helpers/Form';
import { InputField } from '../../../../helpers/FormFields/InputField';
import { PureIconButtonHelper } from '../../../../helpers/buttons/PureIconButtonHelper';
import { Translate } from '../../../../helpers/Translate';

interface PaginationFormProps {
  page?: number;
  limit?: number;
  totalItemsCount: number;
  onPaginate: (page: number) => void;
  onPaginateMouseEnter?: ({ nextPage }: { nextPage: number }) => void;
}

function PaginationForm({
  page = 1,
  limit,
  totalItemsCount = 0,
  onPaginate,
  onPaginateMouseEnter
}: PaginationFormProps) {
  const {
    canGoNext,
    canGoPrev,
    navigateNextPage,
    navigatePage,
    navigatePrevPage,
    pageFirstItemNumber,
    pageLastItemNumber,
    registerPage,
    pageValidationError,
    totalPagesCount
  } = usePaginationForm({
    page,
    itemsPerPageLimit: limit,
    totalItemsCount,
    onPaginate
  });

  const handlePageInputKeyPress = useCallback(
    async (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        await navigatePage();
      }
    },
    [navigatePage]
  );

  const handlePrevMouseEnter = useCallback(
    () => onPaginateMouseEnter?.({ nextPage: page - 1 }),
    [page, onPaginateMouseEnter]
  );
  const handleNextMouseEnter = useCallback(
    () => onPaginateMouseEnter?.({ nextPage: page + 1 }),
    [page, onPaginateMouseEnter]
  );

  if (totalPagesCount === 1) {
    return null;
  }

  if (page === 1 && totalItemsCount === 0) {
    return null;
  }

  return (
    <Form>
      <div className="flex flex-col items-center justify-between sm:flex-row sm:px-6 sm:space-y-0 space-y-3">
        <div>
          <p className="text-sm text-gray-700 dark:text-gray-300">
            <Translate id="words.showing" />
            &nbsp;
            <span className="font-medium">{pageFirstItemNumber}</span>
            &nbsp;-&nbsp;
            <span className="font-medium">{pageLastItemNumber}</span>
            &nbsp;
            <Translate id="words.of" />
            &nbsp;
            <span className="font-medium">{totalItemsCount}</span>
            &nbsp;
            <Translate id="models.results.pluralGenitiveLowerCase" />
          </p>
        </div>
        <div>
          <nav
            className="inline-flex relative rounded-md z-0"
            aria-label="Pagination"
          >
            <div className="flex items-center space-x-4">
              <div className="flex items-center space-x-1.5">
                <InputField
                  error={pageValidationError}
                  inputClassName="basic-input leading-tight text-base text-center w-14 number-appearance-none"
                  max={totalPagesCount}
                  min={1}
                  onKeyPress={handlePageInputKeyPress}
                  ref={registerPage.ref}
                  name={registerPage.name}
                  onChange={registerPage.onChange}
                  showError={false}
                  step={1}
                  type="number"
                />
                <span>
                  <Translate id="words.of" />
                </span>
                <span>{totalPagesCount}</span>
              </div>
              <div className="flex -space-x-px rounded-md shadow-sm">
                <PureIconButtonHelper
                  id="prev-page-btn"
                  className="border border-gray-300 dark:border-gray-700 dark:hover:bg-gray-700 dark:text-gray-300 font-medium hover:bg-gray-50 hover:shadow inline-flex items-center px-4 py-2 relative rounded-l-md sm:px-2 text-gray-500"
                  disabled={!canGoPrev}
                  icon={IconsEnum.CHEVRON_LEFT}
                  onClick={navigatePrevPage}
                  onMouseEnter={handlePrevMouseEnter}
                />
                <PureIconButtonHelper
                  id="next-page-btn"
                  className="border border-gray-300 dark:border-gray-700 dark:hover:bg-gray-700 dark:text-gray-300 hover:bg-gray-50 hover:shadow inline-flex items-center px-4 py-2 relative rounded-r-md sm:px-2 text-gray-500"
                  disabled={!canGoNext}
                  icon={IconsEnum.CHEVRON_RIGHT}
                  onClick={navigateNextPage}
                  onMouseEnter={handleNextMouseEnter}
                />
              </div>
            </div>
          </nav>
        </div>
      </div>
    </Form>
  );
}

export default PaginationForm;
