import React, { ReactNode } from 'react';
import cl from 'classnames';

import { ClassName } from '../../../../../types';

import { StickyBottomScrollContainer } from '../../../../../helpers/StickyBottomScrollContainer';

interface IndexTableProps {
  scope: string;
  children: ReactNode;
  className?: ClassName;
  scrollContainerClassName?: ClassName;
  settings?: ReactNode;
}

function IndexTable({
  scope,
  children,
  className,
  settings,
  scrollContainerClassName
}: IndexTableProps) {
  return (
    <div
      className={
        className || 'align-middle inline-block min-w-full xl:px-4 relative'
      }
    >
      <StickyBottomScrollContainer
        settings={settings}
        containerClass={cl(
          scrollContainerClassName ||
            'border-t border-b xl:border bg-white dark:bg-gray-900 dark:border-gray-700 overflow-auto xl:rounded-lg'
        )}
      >
        <table id={`main-${scope}-table`} className="min-w-full relative z-0">
          {children}
        </table>
      </StickyBottomScrollContainer>
    </div>
  );
}

export default IndexTable;
